<template>
    <div class="container-fluid">
        <Navbar active="helice" :userCredentials="user.credentials" />
        <div class="overflow-gidden p-0 cover">
            <b-modal hide-header hide-footer v-model="addMediaModal" size="xl">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="custom-color-secondary">
                            Ajouter un media à l'hélice
                        </h2>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <vue-select-image
                            id="my-table"
                            :is-multiple="true"
                            :dataImages="cloudLib"
                            @onselectmultipleimage="onSelectImage($event, item)"
                            :h="`100px`"
                            :w="`100px`"
                        ></vue-select-image>
                    </div>
                </div>
                <div class="row"></div>
                <div class="row mt-4">
                    <div class="col-lg-6 text-center">
                        <b-button
                            class="custom-btn-color-primary"
                            v-on:click="sendMedia"
                            >Envoyer</b-button
                        >
                    </div>
                    <div class="col-lg-6 text-center">
                        <b-button
                            class="custom-btn-color-secondary"
                            v-on:click="openAddMediaModal"
                            >Annuler</b-button
                        >
                    </div>
                </div>
            </b-modal>
            <b-modal v-model="moveGroupModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2 class="custom-color-secondary">
                            Changer de groupe
                        </h2>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h6 class="custom-color-secondary">
                            Groupe actuel:
                            <span class="custom-color-primary">{{
                                selectGroupe
                            }}</span>
                        </h6>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h6>
                            <ul>
                                <span
                                    v-for="helice of helices"
                                    :key="helice.idDevice"
                                >
                                    <li
                                        v-if="checkTab[helice.idDevice] == true"
                                    >
                                        {{ helice.DeviceName }}
                                    </li>
                                </span>
                            </ul>
                        </h6>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h6 class="custom-color-secondary">
                            Sélectionner un groupe
                        </h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-select
                            :options="groupeOptions"
                            v-model="groupMoveSelect"
                        ></b-form-select>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-primary btn-size"
                            v-on:click="moveGroup"
                            >Modifier</b-button
                        >
                    </div>
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-secondary btn-size"
                            v-on:click="openMoveGroupModal"
                            >Annuler</b-button
                        >
                    </div>
                </div>
            </b-modal>
            <b-modal v-model="createGroupModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2 class="custom-color-secondary">
                            Ajouter un groupe
                        </h2>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h6 class="custom-color-secondary">Nom du groupe</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-input
                            v-model="groupName"
                            placeholder="Nom"
                        ></b-form-input>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-primary btn-size"
                            v-on:click="createGroup"
                            >Modifier</b-button
                        >
                    </div>
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-secondary btn-size"
                            v-on:click="openCreateGroupModal"
                            >Annuler</b-button
                        >
                    </div>
                </div>
            </b-modal>
            <b-modal v-model="deleteMediaModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2>Supprimer un media</h2>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <p>
                            Attention, vous allez supprimer le groupe
                            <b class="text-danger"
                                >{{ deleteMediaObject.id }} -
                                {{ deleteMediaObject.name }}
                            </b>
                        </p>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-primary btn-size"
                            v-on:click="deleteMedia"
                            >Supprimer</b-button
                        >
                    </div>
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-secondary btn-size"
                            v-on:click="openDeleteMediaModal"
                            >Annuler</b-button
                        >
                    </div>
                </div>
            </b-modal>
            <b-modal v-model="deleteGroupModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2 class="custom-color-secondary">
                            Supprimer un groupe
                        </h2>
                    </div>
                </div>
                <div class="row mt-4 text-center text-center">
                    <div class="col-lg-12">
                        <p>
                            Attention, vous allez supprimer le groupe
                            <b class="text-danger"
                                >{{ selectGroupObject.idAdvertiser }} -
                                {{ selectGroupObject.groupName }}</b
                            >
                        </p>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-primary btn-size"
                            v-on:click="deleteGroup"
                            >Supprimer</b-button
                        >
                    </div>
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-secondary btn-size"
                            v-on:click="openDeleteGroupModal"
                            >Annuler</b-button
                        >
                    </div>
                </div>
            </b-modal>
            <b-modal hide-header hide-footer v-model="editModalOpen"
                ><div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="custom-color-secondary">
                            Modifier l'hélice
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <p class="custom-color-secondary">
                            Vous allez modifier l'hèlice
                            <b class="custom-color-primary"
                                >{{ editModalData.idDevice }} -
                                {{ editModalData.DeviceName }}</b
                            >
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-input
                            v-model="deviceName"
                            placeholder="Nouveau nom"
                        ></b-form-input>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-6 text-center">
                        <b-button
                            class="custom-btn-color-primary"
                            v-on:click="editDeviceName"
                            >Modifier</b-button
                        >
                    </div>
                    <div class="col-lg-6 text-center">
                        <b-button
                            class="custom-btn-color-secondary"
                            v-on:click="closeEditModal"
                            >Annuler</b-button
                        >
                    </div>
                </div></b-modal
            >
            <b-modal hide-header hide-footer v-model="editGroupModal"
                ><div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="custom-color-secondary">
                            Modifier le groupe
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <p class="custom-color-secondary">
                            Vous allez modifier le groupe
                            <b class="custom-color-primary"
                                >{{ editGroupData.idGroup }} -
                                {{ editGroupData.groupName }}</b
                            >
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-input
                            v-model="groupName"
                            placeholder="Nouveau nom"
                        ></b-form-input>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-6 text-center">
                        <b-button
                            class="custom-btn-color-primary"
                            v-on:click="editGroupName"
                            >Modifier</b-button
                        >
                    </div>
                    <div class="col-lg-6 text-center">
                        <b-button
                            class="custom-btn-color-secondary"
                            v-on:click="openEditGroupModal"
                            >Annuler</b-button
                        >
                    </div>
                </div></b-modal
            >
            <div class="row">
                <div class="col-lg-3">
                    <table
                        class="
                            table table-striped
                            custom-bordered
                            table-scrollable
                        "
                    >
                        <thead>
                            <tr>
                                <th scope="col" class="col-lg-4"></th>
                                <th class="custom-table-th col-lg-8">Nom</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-lg-4">
                                    <label class="container">
                                        <input
                                            type="radio"
                                            v-model="selectGroupe"
                                            :value="0"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="col-lg-8">Non groupé</td>
                            </tr>
                            <tr v-for="groupe of groupes" :key="groupe.idGroup">
                                <td class="col-lg-4">
                                    <label class="container">
                                        <input
                                            type="radio"
                                            v-model="selectGroupe"
                                            :value="groupe.idGroup"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="col-lg-8">
                                    {{ groupe.idGroup }} -
                                    {{ groupe.groupName }}
                                </td>
                            </tr>
                            <tr
                                v-for="groupe of allgroups"
                                :key="groupe.idGroup"
                            >
                                <td class="col-lg-4">
                                    <label class="container">
                                        <input
                                            type="radio"
                                            v-model="selectGroupe"
                                            :value="groupe.idGroup"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="col-lg-8">
                                    <span class="custom-color-primary"
                                        >{{ groupe.idGroup }} -
                                        {{ groupe.groupName }}</span
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-6">
                    <table
                        class="
                            table table-striped
                            custom-bordered
                            table-scrollable
                        "
                    >
                        <thead>
                            <tr>
                                <th class="col-lg-1 text-center">
                                    <label class="container text-center">
                                        <input
                                            type="checkbox"
                                            v-model="checkAll"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </th>
                                <th class="custom-table-th col-lg-2">
                                    Code de l'hélice
                                </th>
                                <th class="custom-table-th col-lg-2">Nom</th>
                                <th class="custom-table-th col-lg-1">Modèle</th>
                                <th class="custom-table-th col-lg-2">
                                    MAC adress
                                </th>
                                <th class="custom-table-th col-lg-1">Statut</th>
                                <th class="custom-table-th col-lg-2">Groupe</th>
                                <th class="col-lg-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="helice of helices"
                                :key="helice.idDevice"
                            >
                                <td class="col-lg-1">
                                    <label class="container">
                                        <input
                                            type="checkbox"
                                            v-model="checkTab[helice.idDevice]"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="col-lg-2">{{ helice.idDevice }}</td>
                                <td class="col-lg-2">
                                    {{ helice.DeviceName }}
                                </td>
                                <td class="col-lg-1">{{ helice.Model }}</td>
                                <td class="col-lg-2">
                                    {{ helice.MacIpAddress }}
                                </td>
                                <td class="col-lg-1">{{ helice.RunStatus }}</td>
                                <td class="col-lg-2">{{ helice.IdGroup }}</td>
                                <td class="col-lg-1">
                                    <b-icon-eye
                                        font-scale="1.5"
                                        class="pointer"
                                        v-on:click="
                                            changeContenu(helice.MacIpAddress)
                                        "
                                    ></b-icon-eye>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-3">
                    <b-card-group>
                        <b-card
                            header-class="custom-card-header"
                            class="custom-card-body"
                        >
                            <template #header> Opérations </template>
                            <b-card-body class="reduce-padding"
                                ><div class="row text-left">
                                    <div
                                        class="col-lg-12"
                                        v-on:click="
                                            buttonSection.device =
                                                !buttonSection.device
                                        "
                                    >
                                        <span class="text-white pointer"
                                            >Hélices
                                            <b-icon-chevron-up
                                                v-if="
                                                    !buttonSection.device
                                                " /><b-icon-chevron-down
                                                v-if="buttonSection.device"
                                        /></span>
                                    </div>
                                </div>
                                <div
                                    class="row text-left panel"
                                    v-if="buttonSection.device"
                                >
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="hélices séléctionnées"
                                                    v-on:click="powerondevice"
                                                >
                                                    <b-icon-power></b-icon-power
                                                    >ON</b-button
                                                >
                                            </div>
                                            <div class="col-lg-3">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="hélices séléctionnées"
                                                    v-on:click="poweroffdevice"
                                                >
                                                    <b-icon-power></b-icon-power
                                                    >OFF</b-button
                                                >
                                            </div>
                                            <div class="col-lg-3">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="formate l'hélice selectionnée"
                                                    v-on:click="formatDevice"
                                                >
                                                    Formatage</b-button
                                                >
                                            </div>
                                        </div>

                                        <div class="row spaced">
                                            <div class="col-lg-6">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="hélice selectionnée"
                                                    v-on:click="
                                                        openAddMediaModal
                                                    "
                                                >
                                                    Ajouter un media</b-button
                                                >
                                            </div>
                                            <div class="col-lg-6">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="client séléctionné"
                                                    v-on:click="editDevice"
                                                >
                                                    Modifier l'hélice</b-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row text-left">
                                    <div
                                        class="col-lg-12"
                                        v-on:click="
                                            buttonSection.group =
                                                !buttonSection.group
                                        "
                                    >
                                        <span class="text-white pointer"
                                            >Groupe
                                            <b-icon-chevron-up
                                                v-if="
                                                    !buttonSection.group
                                                " /><b-icon-chevron-down
                                                v-if="buttonSection.group"
                                        /></span>
                                    </div>
                                </div>
                                <div
                                    class="row text-left panel"
                                    v-if="buttonSection.group"
                                >
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="groupe vide"
                                                    v-on:click="
                                                        openCreateGroupModal
                                                    "
                                                >
                                                    Créer un groupe</b-button
                                                >
                                            </div>
                                            <div class="col-lg-6">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="supprimer le groupe selectionné"
                                                    v-on:click="
                                                        openDeleteGroupModal
                                                    "
                                                >
                                                    Supprimer</b-button
                                                >
                                            </div>
                                        </div>
                                        <div class="row spaced">
                                            <div class="col-lg-7">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="groupe selectionné"
                                                    v-on:click="
                                                        openMoveGroupModal
                                                    "
                                                >
                                                    Changer l'hélice de
                                                    groupe</b-button
                                                >
                                            </div>
                                            <div class="col-lg-5">
                                                <b-button
                                                    variant="outline-light"
                                                    size="sm"
                                                    v-b-tooltip.hover
                                                    title="groupe selectionné"
                                                    v-on:click="
                                                        openEditGroupModal
                                                    "
                                                >
                                                    Modifier le groupe</b-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row text-left">
                                    <div
                                        class="col-lg-12"
                                        v-on:click="
                                            buttonSection.seq =
                                                !buttonSection.seq
                                        "
                                    >
                                        <span class="text-white pointer"
                                            >Séq
                                            <b-icon-chevron-up
                                                v-if="
                                                    !buttonSection.seq
                                                " /><b-icon-chevron-down
                                                v-if="buttonSection.seq"
                                        /></span>
                                    </div>
                                </div>
                                <div
                                    class="row text-left panel"
                                    v-if="buttonSection.seq"
                                >
                                    <div class="col-lg-12">
                                        <b-button
                                            variant="outline-light"
                                            size="sm"
                                            v-b-tooltip.hover
                                            title="supprimer le media selectionné"
                                            v-on:click="openDeleteMediaModal"
                                        >
                                            Supprimer media</b-button
                                        >
                                    </div>
                                </div>
                            </b-card-body></b-card
                        ></b-card-group
                    >
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12">
                    <b-card-group>
                        <b-card
                            header-class="custom-card-header"
                            class="custom-card-body custom-media-group"
                        >
                            <template #header> Contenu de l'hélice </template>
                            <b-card-body class="reduce-padding">
                                <div class="row">
                                    <vue-select-image
                                        id="my-table"
                                        :dataImages="medias"
                                        :useLabel="true"
                                        @onselectimage="
                                            onSelectImageDelete($event, item)
                                        "
                                        :h="`95px`"
                                        :w="`95px`"
                                    ></vue-select-image>
                                </div> </b-card-body></b-card
                    ></b-card-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from "@/components/layout/NavbarClient.vue";
import Config from "@/config";
export default {
    data: function () {
        return {
            user: {},
            groupes: [],
            selectGroupe: "",
            selectGroupObject: {},
            moveGroupObject: {},
            helices: [],
            checkAll: false,
            checkTab: {},
            editCheck: "",
            editModalOpen: false,
            editModalData: {},
            deviceName: "",
            createGroupModal: false,
            deleteGroupModal: false,
            deleteMediaModal: false,
            moveGroupModal: false,
            addMediaModal: false,
            cloudLib: [],
            imageAdd: [],
            medias: [],
            groupName: "",
            groupeOptions: [],
            groupMoveSelect: 0,
            editGroupModal: false,
            editGroupData: {},
            deleteMediaObject: {},
            mediaDeviceSelectId: "",
            config: "",
            allgroups: "",
            buttonSection: {
                device: false,
                group: false,
                seq: false,
            },
        };
    },
    components: {
        Navbar,
    },
    async beforeMount() {
        this.config = Config;
        this.user = this.$store.state.user.user;
        this.groupes = await this.$store.dispatch("group/getGroupList", {
            userName: this.user.username,
        });
        this.groupes = this.groupes.aaData;
        let medias = await this.$store.dispatch("media/getMedias", {
            groupId: 0,
        });
        medias = medias.data.aaData;
        if (this.user.credentials == 10) {
            this.allgroups = await this.$store.dispatch(
                "admin/getAllGroupList"
            );
        }

        for (let media of medias) {
            let jpgName = media.resourcesName.replace(".mp4", ".jpg");
            this.cloudLib.push({
                id: media.uiCode,
                src:
                    this.config.api +
                    "/ui/" +
                    media.advertisersCode +
                    "/" +
                    media.uiCode +
                    "/" +
                    jpgName,
                name: media.resourcesName,
            });
        }
        this.groupeOptions.push({ value: 0, text: "Non groupé" });
        for (let groupe of this.groupes) {
            this.groupeOptions.push({
                value: groupe.idGroup,
                text: groupe.groupName,
            });
        }
    },
    watch: {
        selectGroupe: {
            handler() {
                for (let groupe of this.groupes) {
                    if (groupe.idGroup === this.selectGroupe) {
                        this.selectGroupObject = groupe;
                    }
                }

                this.getHelices();
            },
            deep: true,
        },

        groupes: {
            handler() {
                this.groupeOptions = [];
                this.groupeOptions.push({ value: 0, text: "Non groupé" });
                for (let groupe of this.groupes) {
                    this.groupeOptions.push({
                        value: groupe.idGroup,
                        text: groupe.groupName,
                    });
                }
            },
            deep: true,
        },
        checkAll: function () {
            if (this.checkAll === true) {
                for (let element of this.helices) {
                    this.checkTab[element.idDevice] = true;
                }
            } else {
                for (let element of this.helices) {
                    this.checkTab[element.idDevice] = false;
                }
            }
        },
    },

    methods: {
        getHelices: async function () {
            this.helices = await this.$store.dispatch("group/getDeviceGroup", {
                groupId: this.selectGroupe,
            });
            this.helices = this.helices.data;
        },
        addToGroup: async function () {
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    let macadress = "";
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            macadress = helice.MacIpAddress;
                        }
                    }

                    await this.$store.dispatch("admin/addDeviceToGroup", {
                        userName: this.user.username,
                        deviceId: macadress,
                        groupId: this.editCheck,
                    });
                }
            }
            this.helices = await this.$store.dispatch("admin/getAllDevice");
            this.unbankeds = await this.$store.dispatch(
                "group/getDeviceGroup",
                {
                    groupId: 0,
                }
            );
        },
        editDevice: function () {
            let i = 0;
            let keyData = "";
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value === true) {
                    i++;
                    keyData = key;
                }
            }
            if (i === 1) {
                this.editModalOpen = true;
                this.helices.forEach((helice) => {
                    if (helice.idDevice == keyData) {
                        this.editModalData = helice;
                    }
                });
                this.editModalOpen = true;
            }
        },
        openEditGroupModal: async function () {
            this.groupName = "";
            this.editGroupModal = !this.editGroupModal;
            if (this.editGroupModal == true) {
                for (let groupe of this.groupes) {
                    if (groupe.idGroup == this.selectGroupe) {
                        this.editGroupData = groupe;
                    }
                }
            }
        },
        openDeleteMediaModal: async function () {
            this.deleteMediaModal = !this.deleteMediaModal;
        },
        openCreateGroupModal: async function () {
            this.createGroupModal = !this.createGroupModal;
        },
        openMoveGroupModal: async function () {
            this.moveGroupModal = !this.moveGroupModal;
        },
        openAddMediaModal: async function () {
            this.addMediaModal = !this.addMediaModal;
        },
        createGroup: async function () {
            await this.$store.dispatch("group/createGroup", {
                userName: this.user.username,
                groupName: this.groupName,
            });
            this.groupes = await this.$store.dispatch("admin/getAllGroupList");
            this.openCreateGroupModal();
        },
        openDeleteGroupModal: async function () {
            this.deleteGroupModal = !this.deleteGroupModal;
        },
        deleteGroup: async function () {
            await this.$store.dispatch("group/deleteGroup", {
                userName: this.user.username,
                groupId: this.selectGroupObject.idGroup,
                groupName: this.selectGroupObject.groupName,
            });
            this.groupes = await this.$store.dispatch("admin/getAllGroupList");
            this.openDeleteGroupModal();
        },
        closeAddToGroup: async function () {},

        editDeviceName: async function () {
            await this.$store.dispatch("admin/updateDeviceName", {
                userName: this.user.username,
                deviceId: this.editModalData.MacIpAddress,
                deviceName: this.deviceName,
            });
            this.helices = await this.$store.dispatch("admin/getAllDevice");
            this.checkTab = {};
            for (let helice of this.helices) {
                this.checkTab[helice.idDevice] = false;
            }
            this.editModalOpen = false;
        },
        powerondevice: async function () {
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    let macadress = "";
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            macadress = helice.MacIpAddress;
                        }
                    }

                    await this.$store.dispatch("admin/poweronoffdevice", {
                        userName: this.user.username,
                        deviceId: macadress,
                        devicePowerOff: 0,
                        devicePowerOn: 1,
                    });
                }
            }
        },
        poweroffdevice: async function () {
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    let macadress = "";
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            macadress = helice.MacIpAddress;
                        }
                    }
                    await this.$store.dispatch("admin/poweronoffdevice", {
                        userName: this.user.username,
                        deviceId: macadress,
                        devicePowerOff: 1,
                        devicePowerOn: 0,
                    });
                }
            }
        },
        onSelectImage: function (data) {
            this.imageAdd = [];
            for (let element of data) {
                this.imageAdd.push({
                    uiCode: element.id,
                    url: element.src,
                    name: element.name,
                });
            }
        },
        onSelectImageDelete: function (data) {
            this.deleteMediaObject = { id: data.id, name: data.name };
        },
        sendMedia: async function () {
            let deviceArray = [];
            let mediaArray = [];
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            deviceArray.push(helice.MacIpAddress);
                        }
                    }
                }
            }
            for (const media of this.imageAdd) {
                mediaArray.push(media.uiCode);
            }
            await this.$store.dispatch("media/sendMedia", {
                userName: this.user.username,
                deviceId: deviceArray.toString(),
                displayImageId: mediaArray.toString(),
            });
            for (let device of deviceArray) {
                for (let media of this.imageAdd) {
                    await this.$store.dispatch("media/createMediaWRAP", {
                        resourceName: media.name,
                        displayImageId: media.uiCode,
                        deviceId: device,
                    });
                }
            }
            await this.$store.dispatch("media/upgradeDevice", {
                userName: this.user.username,
                deviceId: deviceArray.toString(),
            });
            this.openAddMediaModal();
        },
        changeContenu: async function (deviceId) {
            this.medias = [];
            let medias = await this.$store.dispatch("media/getMediaWRAP", {
                deviceId: deviceId,
            });
            this.mediaDeviceSelectId = deviceId;
            for (let media of medias) {
                let jpgName = media.resourceName.replace(".mp4", ".jpg");
                this.medias.push({
                    id: media.id,
                    src:
                        this.config.api +
                        "/ui/" +
                        this.user.userId +
                        "/" +
                        media.displayImageId +
                        "/" +
                        jpgName,
                    alt: media.resourceName,
                    name: media.resourceName,
                });
            }
        },
        formatDevice: async function () {
            let deviceArray = [];
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            deviceArray.push(helice.MacIpAddress);
                        }
                    }
                }
            }
            await this.$store.dispatch("media/formatDevice", {
                userName: this.user.username,
                deviceId: deviceArray.toString(),
                needFormatSd: 1,
            });
            this.changeContenu(this.mediaDeviceSelectId);
        },
        moveGroup: async function () {
            let deviceArray = [];
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            deviceArray.push(helice.MacIpAddress);
                        }
                    }
                }
            }
            await this.$store.dispatch("group/moveDevice", {
                userName: this.user.username,
                deviceId: deviceArray.toString(),
                groupId: this.groupMoveSelect,
            });
            for (let groupe of this.groupes) {
                if (groupe.idGroup === this.selectGroupe) {
                    this.selectGroupObject = groupe;
                }
            }

            this.getHelices();
        },
        editGroupName: async function () {
            await this.$store.dispatch("group/editGroupName", {
                userName: this.user.username,
                groupId: this.editGroupData.idGroup,
                groupName: this.groupName,
            });
            this.groupes = await this.$store.dispatch("group/getGroupList", {
                userName: this.user.username,
            });
            this.groupes = this.groupes.aaData;
            this.openEditGroupModal();
        },
        deleteMedia: async function () {
            // let transformMediaName = this.deleteMediaObject.name;
            // if (this.config.env == "dev") {
            //     transformMediaName = this.deleteMediaObject.name.replace(
            //         ".mp4",
            //         "1.mp4"
            //     );
            // }
            await this.$store.dispatch("media/deleteDeviceMedia", {
                userName: this.user.username,
                deviceId: this.mediaDeviceSelectId,
                fileDelects: this.deleteMediaObject.name,
                mediaId: this.deleteMediaObject.id,
            });
            this.openDeleteMediaModal();
            this.changeContenu(this.mediaDeviceSelectId);
        },

        closeEditModal: function () {
            this.editModalOpen = !this.editModalOpen;
        },
    },
};
</script>
<style>
.panel {
    margin-top: 15px;
    margin-bottom: 15px;
}
.spaced {
    margin-top: 10px;
}
.custom-card-header {
    color: #0e476b;
    background-color: white;
}
.custom-card-body {
    background-color: #11a9b9;
    border: 2px solid #11a9b9;
}
.reduce-padding {
    padding-top: 0;
    padding-bottom: 0;
}
.custom-media-group {
    max-height: 300px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.table-scrollable tbody {
    height: 400px;
    overflow-y: auto;
    width: 100%;
}
.table-scrollable thead,
.table-scrollable tbody,
.table-scrollable tr,
.table-scrollable td,
.table-scrollable th {
    display: block;
}
.table-scrollable tbody td,
.table-scrollable thead > tr > th {
    float: left;
    border-bottom-width: 0;
}
</style>
