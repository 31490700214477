<template>
    <b-navbar>
        <div class="col-lg-2"></div>
        <div class="col-lg-4">
            <h1>
                <img
                    src="../../../public/holeffect_logo.svg"
                    class="img-fluid logo-size"
                />
                <span class="custom-color-primary">HOL</span
                ><span class="custom-color-secondary">EFFECT</span>
            </h1>
        </div>
        <div class="col-lg-6">
            <ul class="nav nav-tabs">
                <li :class="style.helice" v-on:click="goHelices">
                    <a class="nav-link">Liste Hélices</a>
                </li>
                <li :class="style.cloud" v-on:click="goCloud">
                    <a class="nav-link">Cloud séq</a>
                </li>
                <li :class="style.creator">
                    <a class="nav-link" href="http://editor.holeffect.fr/"
                        >3D Création</a
                    >
                </li>
                <li
                    :class="style.clientmanagement"
                    v-on:click="goManageClient"
                    v-if="userCredentials == 10"
                >
                    <a class="nav-link">Gestion clients</a>
                </li>
            </ul>
        </div>
    </b-navbar>
</template>
<script>
export default {
    props: {
        active: {
            required: true,
            type: String,
        },
        userCredentials: {
            required: true,
            type: Number,
        },
    },
    data: function () {
        return {
            user: {},
            style: {
                helice: { "nav-item-active": false, pointer: true },
                group: { "nav-item-active": false, pointer: true },
                cloud: { "nav-item-active": false, pointer: true },
                creator: { "nav-item-active": false, pointer: true },
                stats: { "nav-item-active": false, pointer: true },
                clientmanagement: { "nav-item-active": false, pointer: true },
            },
        };
    },

    async beforeMount() {
        this.style[this.active]["nav-item-active"] = true;
    },
    methods: {
        goHelices: function () {
            this.$router.push({ name: "HomeClient" });
        },
        goGroup: function () {
            this.$router.push({ name: "GroupsAdmin" });
        },
        goCloud: function () {
            this.$router.push({ name: "CloudClient" });
        },
        goCreator: function () {
            this.$router.push({ name: "CreatorClient" });
        },
        goManageClient: function () {
            this.$router.push({ name: "ManageClient" });
        },
    },
};
</script>
<style>
.nav-tabs {
    border-bottom: 1px solid #11a9b9;
}
.nav-link {
    color: #0e476b;
}
.nav-link:hover {
    color: #0e476b;
}
.nav-item {
    border: 1px solid #11a9b9;
}
.nav-item-active {
    border: 1px solid #11a9b9;
    background: #11a9b9;
}
.logo-size {
    width: 8%;
}
</style>
